import React , {Component } from 'react';
import logo from '../img/cea-foot.png';
import '../App.css';
import M from 'materialize-css';
import TableV from './TableV';
import { Link } from 'react-router-dom';

import axios from 'axios';

class Footer extends Component {
    constructor (props) {
        super(props);
        this.state = { 
            valores:{
                Data:[]
            }
        }
    }
    getValores = ()=> {
        const URL = 'https://app.helpex.co/WebServices/WSWebs.asmx/getTarifas?nit=21351532&cod=CEAU002';
        //const URL = 'http://192.168.1.2/WebServices/WSWebs.asmx/getTarifas?nit=21351532&cod=CEAU002';

        axios.get (URL)
        .then((result) => {
            
            this.setState ({
                valores: result.data
                
            })
        }).catch((err) => {
            console.log(err)
        });
    }

    componentDidMount() {
        M.AutoInit();
        this.getValores();
    }
    renderValores = () => {
        if (this.state.valores.Data.length === 0) {
            return  <div class="progress">
            <div class="indeterminate"></div>
        </div>
                
        } else {
            const listaval = this.state.valores.Data.map((valor) => {
                
                return <TableV getValores={this.getValores} Nombre={valor.Nombre} Matricula={valor.Matricula} Pension={valor.Pension} />
            });
            return listaval
        }
    }
    render(){
        return(
            <footer>
                <div className="row rowbackgray rowpadtop rowsinmag">
                    {/* Boton footer atencion a padres */}
                    <div className="col s12 m4 right-align colconmagtop">
                        <div className="rowflex">
                            <i class="small material-icons ilinkint white-text">face</i>
                            <a href="#horarios" className="linkint hoverable modal-trigger">Atención a Padres</a>
                        </div>
                    </div>

                    {/* Boton footer valores pension */}
                    <div className="col s12 m3 center-align colconmagtop hide">
                        <div className="rowflex">
                            <i class="small material-icons ilinkint white-text">attach_money</i>
                            <a href="#valores" className="linkint hoverable modal-trigger">Valores de Pension</a>
                        </div>
                    </div>

                    {/* Boton footer atencion Ingresar a Helper */}
                    <div className="col s12 m4 left-align colconmagtop">
                        <div className="rowflex">
                            <i class="small material-icons ilinkint white-text">send</i>
                            <a href="https://autonomo.ieduca2.com/login" className="linkint hoverable" target="_blank">Ingresar a iEduca</a>
                        </div>
                    </div>

                     {/* Boton footer Mi Pago Amigo */}
                     <div className="col s12 m4 left-align colconmagtop">
                        <div className="rowflex">
                            <i class="small material-icons ilinkint white-text">payment</i>
                            <a href="https://www.mipagoamigo.com/MPA_WebSite/ServicePayments/StartPayment?id=1709&searchedCategoryId=&searchedAgreementName=colegioauto" className="linkint hoverable" target="_blank">Mi Pago Amigo</a>
                        </div>
                    </div>
                </div>
                <div id="horarios" class="modal modal-fixed-footer">
                    <div class="modal-content">
                        <div className="row rowblue white-text">
                            <h4 className="center-align">Atención a Padres de Familia</h4>
                            <hr className="hrwhite"/>
                        </div>  
                        <div class="row">
                            <p className="left-align">Para el Centro Educativo Autónomo la comunicación es uno de los pilares fundamentales del sistema educativo, por eso tenemos a su disposición una serie de canales de información que le permitirá acceder a noticias de primera mano y además realizar preguntas sobre temas puntuales.</p>
                            <ul class="collection">
                                <li class="collection-item"><i class="material-icons left blueIcon">send</i>A través de la plataforma académica Helper es posible enviar y recibir mensajes de docentes y directivos. </li>
                                <li class="collection-item"><i class="material-icons left blueIcon">send</i>Nuestra línea de whatsapp 304 618 8902 permite enviar información inmediata y urgente. </li>
                                <li class="collection-item"><i class="material-icons left blueIcon">send</i>Nuestros teléfonos fijos  (604) 444 58 02 y (604) 471 50 25 permiten comunicarse con Secretaria y desde allí tramitar las necesidades. </li>
                                <li class="collection-item"><i class="material-icons left blueIcon">send</i>Las redes sociales con las que contamos son Facebook e Instagram, allí nos encuentran como @Ceautonomo, por acá estamos publicnaod noticias e información de actualidad.</li>
                            </ul>
                        </div>                    
                    </div>
                    <div class="modal-footer">
                        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    </div>
                </div>
                <div id="valores" class="modal modal-fixed-footer">
                    <div class="modal-content">
                    <div className="row rowblue white-text">
                            <h4 className="center-align">Costos Educativos</h4>
                            <hr className="hrwhite"/>
                            <p className="center-align">Los costos educativos en la institución estan estipulados para el año actual de la siguiente manera:</p>
                        </div>
                        <div className="row">
                            <table className="striped">
                            <thead>
                                <tr>
                                    <th>Nivel</th>
                                    <th>Matricula</th>
                                    <th>Pensión</th>
                                </tr>
                            </thead>
                            <tbody>
                               {this.renderValores()}
                            </tbody>              
                        </table>     
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    </div>
                </div>
                <div className="rowflex">
                    <div className="divheight">
                        <img src={logo} alt="CEA" className="imgprin"/>
                    </div>
                    <div className="divheight">
                        <p className="white-text valign-wrapper">Calle 104 # 68 A - 77 | Pbx: 444 5802 - Whp: 304 618 8902</p>
                    </div>
                </div>
                <div className="rowflex">
                    <div className="divbordetop">
                        <p className="pfooter white-text">Sitio desarrollado por <a href="http://www.helpex.co" target="_blank" className="white-text">Helpex</a> & Estudio Tándem / Todos los derechos reservados 2020</p>
                        <Link to={'/colegio'} className="afooter white-text">El Colegio</Link>
                        <Link to={'/admisiones'} className="afooter white-text">Admisiones</Link>
                        <a href="https://www.instagram.com/ceautonomo/" target="_blank" className="afooter white-text">Galeria</a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;