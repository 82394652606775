import React , {Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render(){
        return(
            <div className="row rowconmagtop">
                <Link to={this.props.siclick}>
                    <img className="responsive-img imgbanner" src={this.props.ruta} alt="Banner"/>
                </Link>
            </div>
        );
    }
}

export default Banner;