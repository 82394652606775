import React , { Component } from 'react';
import '../App.css';
import M from 'materialize-css';

class MainDash extends Component{
    render(){
        return(
            <div>
                <h2>DashBoard</h2>
            </div>
        );
    }
}

export default MainDash;