import React, { Component } from 'react';
import '../App.css';
import imgbanner from '../img/banner3.png';
import M from 'materialize-css';

import Navbar from './Navbar';
import Banner from './Banner';
import Footer from './Footer';
import Btnfloat from './btnfloat';

class Proyectos extends Component {
    render() {
        return (
            <div>
                <Navbar opcion="op3"/>
                <Banner ruta={imgbanner} siclick={'#'}/>
                <div className="container-fluid">
                    <div class="gridProjects">
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">CEAESCRITOR</span>
                                <p className="pjustify">Este proyecto surge de la importancia de la literatura, escritura y oralidad en el aprendizaje y la enseñanza de la lengua castellana. A partir de estos procesos hay una vinculación con la misión de formar seres íntegros y con una mirada crítica ante los hechos que rodean la contemporaneidad. Además de los aspectos formales de la comprensión lectora y la producción escrita surgen otros aspectos que se relacionan con las experiencias y la necesidad de expresión de las y los estudiantes del Centro Educativo Autónomo, por este motivo el concepto de literatura también se conecta con la creación y los procesos vitales que atraviesan a todo ser humano.</p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">DEMOCRACIA</span>
                                <p className="pjustify">Nuestro Proyecto de Democracia Institucional es visto como un espacio que permite a los estudiantes formar valores, realizar actividades institucionales que se desarrollan no solo en el aula sino también fuera de ella. esto a través de la participación democrática desde escenarios como el gobierno escolar, los miembros de la comunidad educativa tienen la oportunidad de aprender democráticamente de la experiencia cotidiana de los espacios y procesos involucrados. Este proyecto también permite el desarrollo de habilidades cívicas, como la percepción, la emoción y la comunicación con la aplicación de métodos participativos como: diagnóstico participativo, grupos de orientación, campañas para elecciones de gobierno escolar, etc.</p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">EDUCACIÓN SEXUAL </span>
                                <p className="pjustify">El proyecto pedagógico institucional sexualidad e identidad de género busca desarrollar actividades de fortalecimiento personal, familiar, institucional y social de los estudiantes. En este sentido, se hace indispensable una formación que siente las bases para la construcción de una identidad y una subjetividad que sea individual y libre, partiendo de valores ciudadanos de reconocimiento y respeto, sin reproducciones ni copias, que contribuya a la formación integral de los futuros ciudadanos, al respecto, DUBET (2007) afirma que  la formación de ciudadanía no es sólo cuestión de principios y valores, se inscribe también en la misma forma de la escolarización, en el modo como se realizan los aprendizajes, en un sistema de disciplina, en un conjunto de normas. Nace sobre todo de una forma escolar. </p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">EXPOTALENTO</span>
                                <p className="pjustify">El proyecto de Expo-talento consiste en rescatar la riqueza del folclor colombiano y el descubrimiento de habilidades artísticas en los estudiantes, fortaleciendo en ellos el desarrollo físico, social, afectivo, psicológico, etc; se trabajan diferentes tipos de lenguaje, artes visuales, expresión corporal, arte, música y teatro. Este proyecto se desarrolla con el acompañamiento de docentes, padres de familia, algunos miembros de la comunidad y los estudiantes que son los protagonistas del evento.</p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">INCLUSIÓN</span>
                                <p className="pjustify">El Centro Educativo Autónomo en sus 45 años de vida institucional ha estado siempre comprometido con una formación integral de calidad, la cual se ciñe a principios enmarcados en la pluralidad, la diversidad, el respeto y sobre todo la integralidad que logre en sus procesos educativos.  Es así, como el proyecto CEA DIVERSO traza en la institución un camino basado en el reconocimiento de las habilidades, capacidades, alcances y necesidades de quienes habitan el colegio. 
Dicho proyecto se construye con un fundamento legal basado en el decreto 1421 de 2017, pero sobre todo se consolida como una apuesta institucional encaminada al reconocimiento y potenciación de la diversidad tan latente en nuestro medio, este proyecto es la materialización de nuestra filosofía ser una institución formadora de personas integras, entendiendo esa integralidad como el desarrollo pleno del individuo desde todas sus capacidades. 
</p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">INGLÉS</span>
                                <p className="pjustify">En la actualidad es una necesidad el aprender una lengua extranjera puesto que la globalización nos exige a aprenderla debido a la competitividad y diversos contextos que rodean nuestras vidas; es por esto que el proyecto: English language has other ways to learn it, especially when we have fun. surge como una alternativa más continuar con los procesos de aprendizaje una lengua extranjera que nos brinda y ofrece el CEA para alcanzar los objetivos. En estos clases de actividades se evidencia es donde se evidencia que los muchachos quieren aprender Inglés como lengua extranjera ; por esto mismo,  Después de realizar un arduo diagnóstico basado en el aprendizaje del inglés como lengua extranjera de los estudiantes de básica primaria y  básica secundaria del C.E.A, surgió la necesidad de crear un proyecto en pro del proceso de aprendizaje de cada uno de los educandos. Este proyecto llamado en Español, idioma Ingles tiene otras maneras de aprenderlo, especialmente cuando nos divertimos busca que el área de humanidades realice ajustes enfocados en las estrategias y metodologías que se venían utilizando en los diferentes procesos de enseñanza del inglés como lengua extranjera dentro de la institución; la idea principal es crear conciencia en los educandos sobre el uso del inglés en la vida cotidiana creando a través de la diversión el aprendizaje de una lengua extranjera.</p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">INFRAESTRUCTURA</span>
                                <p className="pjustify">Con este proyecto se pretende lograr la equidad, la paz, el respeto a la naturaleza, y a la infraestructura  que nos brinda la institución para lograr una mejor educación integral, demostrando que el medio más eficaz para promover y consolidar los cambios necesarios es motivar un aumento de la conciencia de toda la comunidad educativa sobre el valor y el cuidado que se debe tener con la infraestructura y el mobiliario que  nos pertenece. 
Este también ofrece  un  trabajo basado en la formación de individuos participativos, solidarios y empoderados que sean capaces de construir sociedades sostenibles basadas en sus propias maneras, capacidades, sueños y particularidades, el reto es promover el sentido de pertenencia por la institución cuidando la infraestructura  que nos posibilita una educación con calidad.
</p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">MOVILIDAD</span>
                                <p className="pjustify">El proyecto de Movilidad Escolar en el CEA es fundamental para fortalecer la conciencia del espacio-tiempo de la institución, a la cual se tienen que vincular todos los integrantes del Centro Educativo Autónomo, en diferentes espacios y  temporalidades; de modo que no sea sólo un asunto de atender los problemas relacionados con la salida de los estudiantes, o en los descansos, sino también el uso del espacio dentro  del aula, la biblioteca, y demás espacios de la institución. Si bien en el manual de convivencia se indican las diferentes normas que definen los comportamientos esperados, el proyecto pretende trascender lo normativo, mediante diferentes actividades, señalizaciones y simulacros.  </p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">SERVICIO SOCIAL</span>
                                <p className="pjustify">El proyecto institucional “AYUDO A MI COMUNIDAD CON CALIDAD “del Centro Educativo Autónomo del barrio de pedregal municipio de Medellín; Contribuye con  la filosofía del colegio “En Formación de estudiantes íntegros productivos a la sociedad; esto se ve reflejado en el momento que nuestros educandos comprometidos con la comunidad  realizan  actividades culturales, creativas, recreativas y tutoriales; con  personas del entorno y de la misma institución educativa. Es el compromiso de este proyecto atender a las necesidades integrándose al plan y programas de estudio de estudio como: Apoyo con la elaboración de material didáctico,  semillero de futbol, proyecto de acompañamiento de la mano CEA. Proyecto de la feria de la ciencia, organización de archivos en secretaria y biblioteca; apoyo a los docentes en las diferentes actividades entre otros. en los cuales demuestran su vocación y actitud de servicio, además de contribuir a la proyección social y comunitaria de la institución </p>
                            </div>
                        </div>
                        <div class="card colorcard">
                            <div class="card-content">
                                <span class="card-title titblue titmay">FERIA DE LA CIENCIA / INVESTIGACIÓN ESCOLAR </span>
                                <p className="pjustify">La realización de una feria de las ciencias es fundamental para el crecimiento académico y personal de los estudiantes, permitiendo la exploración de nuevas áreas del conocimiento y el desarrollo de habilidades y destrezas fundamentales para su éxito futuro. Además, su impacto se extiende a toda la comunidad educativa, generando un ambiente de aprendizaje dinámico y fomentando el interés en la ciencia y la tecnología.</p>
                            </div>
                            {/* <div class="card-action">
                                { <a href="https://drive.google.com/file/d/0B6HaFXTT0IHRVkhfTzVoSUNfdnM/edit" className="acardproy white-text z-depth-1" target="_blank">Descargar</a>}
                            </div> */}
                        </div>
                    </div>
                </div>
                <Btnfloat/>
                <Footer />
            </div>
        );
    }
}

export default Proyectos;