import React, { Component } from 'react';
import '../App.css';
import imgbanner from '../img/banner4.png';
import M from 'materialize-css';

import Navbar from './Navbar';
import Banner from './Banner';
import Footer from './Footer';
import Btnfloat from './btnfloat';

class Admisiones extends Component {
    render() {
        return (
            <div>
                <Navbar opcion="op4"/>
                <Banner ruta={imgbanner} siclick={'#'}/>
                <div className="row rowbackgray rowpadtop">
                    <div className="container">
                        <h3 className="titblue center-align hsinmagtop">Proceso de Admisión</h3>
                        <hr className="hrcolegio" />
                        <p className="pcol">En el Centro Educativo Autónomo nos gusta hacer las cosas bien, 
                        por esta razón en esta sección usted encontrará los grados y los requisitos para iniciar el proceso de preinscripción a través de nuestra plataforma virtual.
                        </p>
                        <div className="row">
                            <table className=" centered striped">
                                <thead>
                                    <tr>
                                        <th>Grado</th>
                                        <th>Edad</th>
                                        <th>Requisito de Escolaridad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Transición</td>
                                        <td>5 años cumplidos</td>
                                        <td>No Aplica</td>
                                    </tr>
                                    <tr>
                                        <td>Primero</td>
                                        <td>6 años cumplidos</td>
                                        <td>Transición Aprobado</td>
                                    </tr>
                                    <tr>
                                        <td>Sexto</td>
                                        <td>Máximo 12 años</td>
                                        <td>Quinto Aprobado</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="pcol">A continuaición detallamos el proceso que deberá seguir para realizar el proceso de inscripción completo:</p>
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m1">
                                <h2 className="titblue hsinmagtop hnum">01.</h2>
                            </div>
                            <div className="col s12 m11">
                                <p className="pnum">Ingresa en el botón a continuación para realizar la preinscripción, recuerda que todos los datos solicitados son necesarios.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 center-align colconmagtop">
                                <a href="https://app.helpex.co/inscriptions.aspx?n=21351532&c=CEAU002&i=0&p=0" className="helper abutton white-text z-depth-1" target="_blank">Realizar Preinscripción</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row rowbackgray">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m1">
                                <h2 className="titblue hsinmagtop hnum hpconmagtop">02.</h2>
                            </div>
                            <div className="col s12 m11">
                                <p className="pnum hpconmagtop">En un lapso máximo de 15 dias hábiles de haber realizado la preinscripción, recibirás un correo electrónico, informándote si hace falta más información o si puedes continuar con el proceso.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m1">
                                <h2 className="titblue hsinmagtop hnum">03.</h2>
                            </div>
                            <div className="col s12 m11">
                                <p className="pnum">El siguiente paso es descargar el informe de procedencia que te enviaremos al correo electrónico, hacerlo diligenciar en la institución donde estudia el niño o la niña para traerlo en sobre sellado el día de la entrevista y acceder al link que te enviaremos para cargar los documentos solicitados en el mensaje, en este paso además podrás seleccionar la fecha y hora de la cita para la entrevista.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row rowbackgray">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m1">
                                <h2 className="titblue hsinmagtop hnum hpconmagtop">04.</h2>
                            </div>
                            <div className="col s12 m11">
                                <p className="pnum hpconmagtop"><strong>NO</strong> olvides la puntualidad el dia de la cita, además recuerda que deben estar presentes los padres y el aspirante a la institución.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m1">
                                <h2 className="titblue hsinmagtop hnum">05.</h2>
                            </div>
                            <div className="col s12 m11">
                                <p className="pnum">Si tienes dudas con el proceso recuerda que puedes comunicarte con nosotros a los telefonos que aparecen abajo.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Btnfloat/>
                <Footer />
            </div>
        );
    }
}

export default Admisiones;